@import './../../style/abstracts/mixins';

.support_cc_item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    width: 88%;

    @include respond(phone) {
        height: 50px;
    }



    &_cont {
        display: flex;
        align-items: center;
        box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
        background: #fff;

        @include respond(phone) {
            height: 50px;
            width: 200px;
            margin-right: 10px;
        }






        .business_info {

            p {
                margin: 0;
            }

            .business_name {
                font-size: 18px;
                width: 180px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

}