@import '../../style/abstracts/mixins';

.rdcmessage_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 150px;


    @include respond(phone) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


.customer_list {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include respond(phone) {
        width: 90%;
        margin: 10px 0;
        padding: 0px;
    }

    &_search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;

        @include respond(phone) {
            width: 112%;
        }
    }

    &_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100dvh - 350px);
        min-height: 300px;

        @include respond(phone) {
            flex-direction: row;
            height: 60px;
            padding: 20px;
            padding-left: 15px;
            min-height: 70px;
            overflow-x: scroll;
            overflow-y: hidden;
        }
    }
}