@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,300;0,9..40,400;0,9..40,600;0,9..40,700;1,9..40,100;1,9..40,300;1,9..40,400;1,9..40,600;1,9..40,700&display=swap');
@import './style/main';

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

p {
  color: #324a59;
  word-wrap: break-word;
}

h2 {
  color: #232d42;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar:horizontal {
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #9cbef6;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #598ad7;
  border-radius: 3px;
  max-height: 5px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #002661;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: 'DM Sans';
}