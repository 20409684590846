@import './../../style/abstracts/mixins';

.referrals_details_wrapper {
    display: flex;
    width: 100%;

    @include respond(phone) {
        flex-direction: column;
        align-items: center;
    }
}

.referral_details_section {
    width: 64%;
    padding: 20px;
    margin-right: 2%;
    border-radius: 10px;

    @include respond(phone) {
        width: 100%;
        margin-right: 0px;
        padding: 0px;
    }

    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #D5E7F7;

        .title_logo {
            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                border-radius: 10px;
                box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
                margin-right: 10px;
            }
        }
    }

    .journey_details_wrapper {
        margin: 30px 0;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 10px;

        @include respond(phone) {
            padding: 10px
        }
    }

    &_det0 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px;

        @include respond(phone) {
            flex-direction: column;
        }

        &_p1 {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
            margin-right: 6%;

            @include respond(phone) {
                margin-right: 0;
            }

            &>* {
                width: 48%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }


    }

    &_det1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px;

        @include respond(phone) {
            flex-direction: column;
        }

        &_p1 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }

        &_p2 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }
    }

    &_det2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px;

        @include respond(phone) {
            flex-direction: column;
        }

        &_p1 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }


        }

        &_p2 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }
    }


    .customer_details_wrapper {
        margin: 30px 0;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 10px;

        @include respond(phone) {
            padding: 10px;
        }
    }

    &_det3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px;

        @include respond(phone) {
            flex-direction: column;
        }

        &_p1 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }


        }

        &_p2 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }
    }

    &_det4 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 20px 0px;
        margin-top: 40px;

        @include respond(phone) {
            flex-direction: column;
            justify-content: center;
        }

        &_p1 {
            display: flex;
            width: 67%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }
        }

        &_p2 {
            display: flex;
            width: 27%;
            justify-content: center;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}



.appliedcomp_section_wrapper {
    margin-top: 30px;
    width: 34%;


    @include respond(phone) {
        width: 100%;
        display: flex;
    }


    .appliedcomp_section {
        @include respond(phone) {
            width: 100%;
        }
    }


    .appliedcomp_cards {
        height: 560px;
        overflow-y: scroll;
        padding: 20px;

        @include respond(phone) {
            padding: 20px;
            overflow-y: hidden;
            height: 100%;
        }



    }
}


.footer_section {
    height: 150px;
    width: 100%;
}