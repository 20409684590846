@import '../../style/abstracts/mixins';

.testimonials_cards_item {
    width: 300px;
    max-width: 350px;
    border-radius: 10px;
    box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;

    @include respond(phone) {
        width: 100%;
    }


    .title_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo_comp {
            display: flex;
            align-items: center;

            &_star {
                p {
                    // display: -webkit-box;
                    // -webkit-line-clamp: 1;
                    // -webkit-box-orient: vertical;
                    // overflow: hidden;
                }

                .rating_wrapper {
                    display: flex;
                    align-items: center;

                    p {
                        margin-right: 5px;
                    }
                }
            }
        }

        .temp_status {
            background-color: #DAF3E2;
            padding: 2px 10px;
            border-radius: 100px;

            p {
                color: #47C56C;
            }
        }



        p {
            margin: 0px;
        }
    }

    .content_wrapper {
        p {
            margin: 10px 0px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    .action_buttons {
        display: flex;
        justify-content: space-between;

        .view_del {
            display: flex;
        }
    }
}