@import './../../style/abstracts/mixins';

.templates_details_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

}

.template_details_section {
    width: 80%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;


    @include respond(phone) {
        width: 100%;
        padding: 0px;
    }



    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #D5E7F7;

        .title_logo {
            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                border-radius: 10px;
                box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
                margin-right: 10px;
            }
        }

        .temp_status {
            background-color: #D7F2DF;
            border-radius: 10px;

            p {
                color: #47C56C;
                padding: 5px 10px;
            }
        }
    }


    .customer_details_wrapper {
        margin: 30px 0;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 10px;
    }

    &_det3 {
        display: flex;
        justify-content: space-between;
        margin: 20px 0px;

        @include respond(phone) {
            flex-direction: column;
        }

        &_p1 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }

        &_p2 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }


            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }
        }
    }

    &_det4 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 20px 0px;
        margin-top: 40px;

        @include respond(phone) {
            flex-direction: column;
            padding: 10px;
        }

        &_p1 {
            display: flex;
            width: 67%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;


            pre {
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
                font-family: 'DM Sans';
            }

            @include respond(phone) {
                width: 100%;
                margin-right: 0px;
            }

        }

        &_p2 {
            display: flex;
            width: 27%;
            justify-content: center;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
                align-items: center;
            }
        }
    }


}

.vp_images_wrapper {
    margin: 30px 0;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .vpd_images {
        display: flex;
        flex-wrap: wrap;

        .vpd_image_item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            width: 250px;
            height: 150px;
            margin-right: 10px;
            margin-bottom: 10px;

            @include respond(phone) {
                width: 100%;
                height: 200px;
            }


            .vpd_image_action {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 30px;
                background-color: #FFC6C6;
                border-radius: 3px;
                box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
                position: absolute;
                margin: 15px;
                right: 0;
                cursor: pointer;
                transition: .5s ease;
                z-index: 888;
            }

            img {
                width: 250px;
                height: 150px;
                object-fit: cover;
                border-radius: 5px;
                transition: .5s ease;

                @include respond(phone) {
                    width: 100%;

                    height: 200px;
                }
            }

            .zoom_image {
                transition: .5s ease;
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
                cursor: pointer;
            }


            &:hover .vpd_image_action {
                opacity: 1;
            }

            &:hover img {
                opacity: 0.3;
            }

            &:hover .zoom_image {
                opacity: 1;
            }

        }
    }

    .vp_images_action {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_status {
            p {
                color: #47C56C;
            }
        }

        &_button {
            cursor: pointer;
        }
    }
}

.zoomed_image_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .zoomed_image_action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 30px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        position: absolute;
        margin: 15px;
        right: 0;
        top: 0;
        cursor: pointer;
        transition: .5s ease;
    }

    img {
        width: 100%;
        height: 90vh;
        object-fit: cover;
        border-radius: 5px;

        @include respond(phone) {
            height: 50vh;
        }

    }
}


.footer_section {
    height: 150px;
    width: 100%;
}