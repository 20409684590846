@import './../../style/abstracts/mixins';


.graph_section {
    display: flex;
    width: 100%;

    @include respond(phone) {
        flex-direction: column;
    }


    .revenue_graph {
        padding: 15px;
        width: 54%;
        height: 330px;
        margin-right: 2%;
        border-radius: 10px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        @include respond(phone) {
            width: 90%;
            margin-right: 0px;
            padding: 10px;
            height: 250px;
        }


        .graph_header {
            display: flex;
            height: 50px;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {}

        }

        .graph {
            height: 280px;

            @include respond(phone) {
                height: 200px;
            }
        }
    }

    .summary {
        width: 44%;
        border-radius: 10px;
        padding: 15px;
        height: 330px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include respond(phone) {
            width: 90%;
            height: 200px;
            margin-top: 40px;
        }

        &_row1,
        &_row2 {
            display: flex;
            margin-left: 20px;

            @include respond(phone) {
                margin-left: 0px;
            }

            &_item {
                width: 50%;
                height: 150px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @include respond(phone) {
                    height: 100px;
                }

                .summary_icon {
                    background-color: red;
                    padding: 15px;
                    border-radius: 10px;
                    margin-right: 20px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include respond(phone) {
                        width: 30px;
                        height: 30px;
                        margin-right: 15px;
                    }
                }

                .summary_content {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    &_number {
                        font-size: 25px;
                        margin: 0px;

                        @include respond(phone) {
                            font-size: 20px;
                        }
                    }

                    &_title {
                        margin: 0px;

                        @include respond(phone) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}


.stats_section {
    margin-top: 30px;
    display: flex;
    width: 100%;

    @include respond(phone) {
        flex-direction: column;
    }


    .templates_referrals {
        display: flex;
        flex-direction: column;
        width: 64%;
        margin-right: 2%;

        @include respond(phone) {
            width: 100%;
            margin-right: 0px;
            margin-top: 40px;
        }

        .templates {
            // height: 260px;
            border-radius: 10px;

            @include respond(phone) {
                height: 100%;
            }

            &_header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 30px;
                margin-bottom: 15px;

                &_action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    p {
                        color: #47C56C;
                    }
                }
            }

            &_cards {
                display: flex;

                @include respond(phone) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                &>* {
                    width: 300px;
                    margin-right: 10px;

                    @include respond(phone) {
                        width: 90%;
                        margin-right: 0px;
                        margin-top: 10px;
                    }
                }

            }
        }

        .referrals {
            margin-top: 10px;
            border-radius: 10px;

            @include respond(phone) {
                margin-top: 40px;
            }

            &_header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 30px;
                margin-bottom: 15px;
                margin-top: 20px;

                @include respond(phone) {
                    margin-right: 15px;
                }

                &_action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    min-width: 90px;

                    p {
                        color: #47C56C;
                    }
                }
            }

            &_cards {
                display: flex;
                flex-wrap: wrap;


                &>* {
                    width: 220px;
                    margin-top: 10px;
                    margin-right: 10px;

                    @include respond(phone) {
                        width: 100%;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .transactions {
        width: 34%;
        border-radius: 10px;

        @include respond(phone) {
            width: 100%;
            margin-top: 40px;
        }

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 30px;
            margin-bottom: 15px;

            &_action {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                p {
                    color: #47C56C;
                }
            }
        }

        &_cards {
            //
        }
    }
}

.footer_section {
    height: 150px;
    width: 100%;
}