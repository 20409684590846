@import './../../style/abstracts/mixins';

.main {
  width: 100%;
  overflow: hidden;
}

.wrapper {
  display: flex;
  height: 100vh;
}

.main_block_header {
  background: #F3F8FF;
  height: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.main_block_header_title {
  height: 48px;
  border-bottom: 2px solid #D7DCDF;
  display: flex;
  align-items: center;
}

.main_content {
  overflow: scroll;
  height: 100vh;
  position: relative;
  padding: 20px;

  @include respond(phone) {
    padding: 10px;
  }
}