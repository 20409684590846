@import '../../style/abstracts/mixins';

.user_approval_cards_item {
    width: 262px;
    border-radius: 10px;
    box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(phone) {
        width: 100%;
        margin-right: 0px;
    }


    .title_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .logo_comp {
            display: flex;
            align-items: center;

            &_det {
                .business_name {
                    font-weight: bold;
                }

                .contact_name {
                    font-size: 12px;
                }
            }

        }

        .profile_img {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .image_item {

                img {
                    width: 60px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 5px;

                    @include respond(phone) {
                        width: 100px;
                        height: 70px;
                    }
                }
            }
        }



        p {
            margin: 0px;
        }
    }

    .picture_content {
        margin-bottom: 10px;

        h4 {
            margin-bottom: 5px;
        }
    }

    .template_content {
        margin-bottom: 10px;

        h4 {
            margin-bottom: 5px;
        }
    }




    .content_wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }


        .image_item {
            margin-right: 10px;

            img {
                width: 80px;
                height: 50px;
                object-fit: cover;
                border-radius: 5px;

                @include respond(phone) {
                    width: 100px;
                    height: 70px;
                }
            }

        }
    }

    .action_buttons {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .view_del {
            display: flex;
        }
    }

    h4 {
        color: #324A59;
    }
}