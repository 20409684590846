@import '../../style/abstracts/mixins';

.company_messages {
    display: flex;
    flex-direction: column;
}



.navigation_header {
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;

    @include respond(phone) {
        justify-content: space-between;
        padding: 0 10px;
        margin-left: 0;
    }

    &-company {
        background-color: #CADCF7;
        margin-right: 10px;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;

        p {
            color: #324A59;
        }
    }

    &-broadcast {
        background-color: #F3F8FF;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;

        p {
            color: #324A59;
        }
    }
}

.message_main_content {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 150px;


    @include respond(phone) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .company_list {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @include respond(phone) {
            width: 90%;
            margin: 10px 0;
            padding: 0px;
        }

        &_search {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;

            @include respond(phone) {
                width: 112%;
            }
        }

        &_items {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: calc(100dvh - 350px);
            min-height: 300px;

            @include respond(phone) {
                flex-direction: row;
                height: 70px;
                min-height: 70px;
                padding: 20px;
                padding-left: 15px;
                overflow-x: scroll;
                overflow-y: hidden;
            }
        }
    }
}