@import './../../style/abstracts/mixins';

.settings_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}



.left_input_section {
    width: 70%;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;

    height: 100vh;
    overflow: scroll;
    position: relative;

    @include respond(phone) {
        width: 100%;
        justify-content: flex-start;
    }
}

.right_image_section {
    width: 30%;
    background-color: #F3F8FF;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        margin-bottom: 200px;
    }

    @include respond(phone) {
        display: none;
    }
}

.logo_wrapper-settings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.computer_desk_img {
    width: 350px;
    margin-left: -160px;
    z-index: 9999;
}

.main_logo_img {
    width: 150px;
    height: 150px;
    margin-bottom: -20px;
}

.input_fields {
    display: flex;
    flex-direction: column;

}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.welcom_text {
    font-size: 25px;
    font-weight: bold;
    color: #324A59
}

.input_fc {
    width: 385px;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
        width: 300px;
    }

    &_item {
        width: 385px;

        @include respond(phone) {
            width: 300px;
        }
    }
}