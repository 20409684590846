@import './../../style/abstracts/mixins';

.inapp_wallet_details_wrapper {
    display: flex;
    width: 100%;

    @include respond(phone) {
        flex-direction: column;
        align-items: center;
    }
}

.inapp_wallet_details_section {
    width: 64%;
    padding: 20px;
    margin-right: 2%;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
        width: 90%;
        padding: 10px;
        margin-right: 0px;
    }


    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #D5E7F7;

        .title_logo {
            display: flex;
            align-items: center;

        }

        .title_stats {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &_det0 {
        margin-top: 10px;
    }

    &_det1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px;

        @include respond(phone) {
            flex-direction: column;
        }

        &_p1 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }

        &_p2 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }
        }
    }

    &_det2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px;

        @include respond(phone) {
            flex-direction: column;
        }

        &_p1 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: flex-start;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }

        }

        &_p2 {
            display: flex;
            width: 47%;
            justify-content: space-between;
            align-items: flex-start;




            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }

            &>* {
                width: 50%;

                @include respond(phone) {
                    width: 48%;
                }
            }


        }
    }

    &_det3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0px;


        @include respond(phone) {
            flex-direction: column;
        }


        &_p1 {
            display: flex;
            width: 57%;
            justify-content: space-between;
            align-items: center;
            margin-right: 6%;

            pre {
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
                font-family: 'DM Sans';
            }

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }



        }

        &_p2 {
            display: flex;
            width: 37%;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                margin-top: 20px;
            }

        }
    }

    &_images {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 20px 0px;
        flex: 1;

        @include respond(phone) {
            flex-direction: column;
        }




        .cds_image {
            width: 67%;
            margin-right: 6%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }

        }

        .cds_actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 27%;
            align-items: flex-end;



            @include respond(phone) {
                width: 100%;
                margin-top: 20px;

                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            @include respond(sm-phone) {
                flex-direction: column;
            }

        }

        .v_images_wrapper {
            display: flex;
            margin-top: 10px;


            .v_image_item {
                margin-right: 10px;

                img {
                    width: 100px;
                    height: 60px;
                    border-radius: 5px;
                }
            }
        }
    }
}

.transactions__section_wrapper {
    width: 34%;
    height: 450px;

    @include respond(phone) {
        width: 100%;
        margin-top: 30px;
        height: 100%;
    }


    .transactions_section {
        margin-bottom: 30px;

        .transactions_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            &_action {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                p {
                    color: #47C56C;
                }
            }
        }

        .transactions_cards {
            height: 440px;
            overflow-y: scroll;
            padding: 20px;

            @include respond(phone) {
                overflow-y: unset;
                height: 100%;
            }

        }
    }
}

.referred_section_wrapper {
    margin-top: 30px;
    width: 100%;

    @include respond(phone) {}

    .referrals_cards {
        display: flex;
        flex-wrap: wrap;


        &>* {
            width: 220px;
            margin-right: 10px;
            margin-top: 10px;

            @include respond(phone) {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}


.footer_section {
    height: 150px;
    width: 100%;
}