@import './../../style/abstracts/mixins';

.main_wrapper {
    display: flex;
    width: 100%;
    height: 100vh;

    @include respond(phone) {
        justify-content: center;
        align-items: flex-start;

    }

}

.left_image_section {
    width: 30%;
    background-color: #F3F8FF;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include respond(phone) {
        display: none;
    }


}

.right_input_section {
    width: 70%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include respond(phone) {
        width: 100%;
    }
}

.logo_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include respond(phone) {
        margin-top: 70px;
    }
}


.input_fields {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input_fc {
    width: 385px;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
        width: 300px;
    }

    &_item {
        width: 385px;

        @include respond(phone) {
            width: 300px;
        }
    }
}

.computer_desk_img {
    width: 350px;
    margin-right: -175px;
    z-index: 9999;
}

.main_logo_img {
    width: 150px;
    height: 150px;
    margin-bottom: -20px;
}

.input_fields {
    display: flex;
    flex-direction: column;

}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.welcom_text {
    font-size: 25px;
    font-weight: bold;

    @include respond(phone) {
        margin-bottom: 20px;
    }
}