.transactions_cards_item {
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);

    p {
        margin: 0;
    }

    .name_status {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;


        .company_name {}

        .tr_status {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
}
    }

    .date_amount {
        display: flex;
        justify-content: space-between;

        .tr_date {}

        .tr_amount {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }
    }

    .det_answer {
        font-weight: bold;
    }

    .det_answer_status {
        color: #47C56C;
    }
}