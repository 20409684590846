@import './../../style/abstracts/mixins';

.sorting_wrapper {
    display: flex;
    align-items: center;

    @include respond(phone) {}
}

.testimonials_cards {
    display: flex;
    flex-wrap: wrap;

    @include respond(phone) {
        justify-content: center;
    }
}

.testimonials_action_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}


.footer_content {
    margin-bottom: 150px;
}