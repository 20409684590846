@import './../../style/abstracts/mixins';


.main_templates_cards {
    display: flex;
    flex-wrap: wrap;

    @include respond(phone) {
        justify-content: center;
    }
}

.templates_action_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}